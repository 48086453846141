import { useContext, useEffect, useState } from "react";
import { Typography, Grid, Tooltip, Button, IconButton, Modal, Box, TextField, Stack, Chip } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import DevicesIcon from "@mui/icons-material/Devices";
import KeyIcon from "@mui/icons-material/Key";
import Api from "../../api";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { IEntraUser } from "../../interface";
import Paginator from "../../components/Paginator";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EditModal, { MODAL_EDIT_TYPE } from "./OrderModal";
import UserContext from "../../context/userContext";
import style from "./userList.module.css";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export async function usersLoader() {
    return Api.get("/users/");
}

function UserModal({
    show,
    closeModal,
    user,
}: {
    show: boolean;
    closeModal: any;
    user: IEntraUser;
}) {
    if (!user) return;
    return (
        <Modal
            open={show}
            onClose={() => {
                closeModal();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={style.modal}>
                <Typography variant="h5">Brukerinfo</Typography>
                <Grid container spacing={3} sx={{marginTop:"1rem"}}>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            name="first_name"
                            label="Fornavn"
                            variant="standard"
                            value={user.first_name}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            name="last_name"
                            label="Etternavn"
                            variant="standard"
                            value={user.last_name}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ fontWeight: 'bold', margin:"2rem 0 0.5rem 0" }}>Entra Grupper</Box>
                {user.groups.length > 0 ? user.groups.map((group) => <Chip label={group.name} style={{margin:"0.2rem"}}/>) : <p>Ingen Grupper</p>}
                <Box sx={{ fontWeight: 'bold', margin:"1rem 0 0.5rem 0" }}>Enheter</Box>
                {user.devices.length > 0 ? user.devices.map((device) => <Chip label={device.product} style={{margin:"0.2rem"}}/>) : <p>Ingen Enheter</p>}
                <Box sx={{ fontWeight: 'bold', margin:"1rem 0 0.5rem 0" }}>Microsoft Lisenser</Box>
                {user.licenses?.length > 0 ? user.licenses?.map((licenseAssignment) => <Chip label={licenseAssignment.license.name ?? licenseAssignment.license.part_number} style={{margin:"0.2rem"}}/>) : <p>Ingen lisenser</p>}
            </Box>
        </Modal>
    );
}


export const empty_user = {
    id: null,
    first_name: "",
    // middle_name: "",
    last_name: "",
    position: "",
    mobile_phone_number: "",
    comment: "",
    email_username: "",
    activation_date: null,
    account_type: "",
    use_manual_email: false,
};

export default function Users(props) {
    const navigate = useNavigate();
    const data: any = useLoaderData();
    const revalidator = useRevalidator();

    const [result, setResult] = useState<IEntraUser[]>([]);

    const { userData, getAccountTypeName } = useContext(UserContext);
    const [orderUser, setOrderUser] = useState(empty_user);
    const [user, setUser] = useState<IEntraUser | null >();

    const [showModal, setShowModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);

    const [editModalType, setEditModalType] = useState(1);

    useEffect(() => {
        setResult(data.results);
    }, [data]);

    return (
        <>

            <div className="pageHeaderWrapper">
                <Typography variant="h6" className="pageHeader">
                    Brukere
                </Typography>
            </div>
            <div className="paddingPages">
                <EditModal
                    show={showOrderModal}
                    close={() => {
                        setShowOrderModal(false);
                        revalidator.revalidate();
                    }}
                    user={orderUser}
                    setUser={setOrderUser}
                    editModalType={editModalType}
                />
                <UserModal 
                    show={showModal}
                    closeModal={() => setShowModal(false)}
                    user={user}
                />
                {userData?.is_dashboard_admin &&
                    userData.tenant.account_types.length > 0 &&  (
                        <Grid
                            container
                            alignContent={"center"}
                            justifyContent={"space-between"}
                            sx={{ padding: "1rem" }}
                        >
                            <Grid item></Grid>
                            <Grid item>
                                <>
                                    {<Button
                                        variant="outlined"
                                        startIcon={<MoreTimeIcon />}
                                        size="small"
                                        sx={{ marginRight: "1rem" }}
                                        onClick={() => {
                                            navigate("/users/orders");
                                        }}
                                    >
                                        Bestilte brukere
                                    </Button>}

                                    <Button
                                        variant="contained"
                                        startIcon={<GroupAddIcon />}
                                        size="small"
                                        onClick={() => {
                                            setEditModalType(
                                                MODAL_EDIT_TYPE.CREATE_NEW_ORDER,
                                            );
                                            setShowOrderModal(true);
                                        }}
                                    >
                                        Bestill ny bruker
                                    </Button>
                                </>
                            </Grid>
                        </Grid>
                    )}
                <table className="table">
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Type</th>
                            <th>Epost-adresse</th>
                            <th>Mobilnummer</th>
                            <th>MFA</th>
                            <th className="tableCenter">
                                <Tooltip title="Antall Grupper">
                                    <GroupIcon />
                                </Tooltip>
                            </th>
                            <th className="tableCenter">
                                <Tooltip title="Antall Enheter">
                                    <DevicesIcon />
                                </Tooltip>
                            </th>
                            <th className="tableCenter">
                                <Tooltip title="Antall Lisenser">
                                    <KeyIcon />
                                </Tooltip>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {result?.map((user: IEntraUser) => (
                            <tr key={user.id}>
                                <td>
                                    {user.first_name} {user.last_name}
                                </td>
                                <td>{getAccountTypeName(user.account_type)}</td>
                                <td>{user.email_address}</td>
                                <td>{user.mobile_phone_number}</td>
                                <td>
                                    {user.mfa_activated ? (
                                        <CheckCircleOutlineIcon color="success" />
                                    ) : null}
                                </td>
                                <td className="tableCenter">
                                    {user.group_count}
                                </td>
                                <td className="tableCenter">
                                    {user.device_count}
                                </td>
                                <td className="tableCenter">
                                    {user.license_count}
                                </td>

                                {<td>
                                    <IconButton
                                        onClick={() => {
                                            setEditModalType(
                                                MODAL_EDIT_TYPE.UPDATE_ENTRA,
                                            );
                                            setUser(user as IEntraUser);
                                            setShowModal(true);
                                        }}
                                    >
                                        <OpenInFullIcon />
                                    </IconButton>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Paginator
                    setResult={(_res) => setResult(result.concat(_res))}
                />{" "}
            </div>
        </>
    );
}
